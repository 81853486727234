import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  page: any = 1;

  constructor(public auth: AuthService, public router: Router) { }

  ngOnInit() {
    if (!this.auth.getToken()) this.router.navigateByUrl('login');
    this.auth.checkToken(res => {
      if (!res.status) this.router.navigateByUrl('login');
    })
  }

}
