export class Node {
  constructor(
    public id = 0,
    public key = 0,
    public name = '',
    public nameEn = '',
    public nickName= '',
    public birthDate = '',
    public birthPlace = '',
    public gender = 'male',
    public email = '',
    public about = '',
    public pid = 0,
    public parent = 0,
    public sequence = 1,
    public hid = 0,
    public img = '',
    public source = '',
    public location = '',
    public phone = '',
    public parents = [],
    public children = [],
    public brothers = [],
    public partners = [],
    public isActive = 1
  ) {}
}
