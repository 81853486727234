import { Injectable } from '@angular/core';
import {base} from './config';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  url = base;

  constructor(public http: HttpClient) { }

  sendMail(email, title, message, callback) {
    this.http.post(this.url + 'contact', {email, title, message}).subscribe(res => callback(res))
  }

  sendForgetPasswordMail(email, name, callback) {
    this.http.post(this.url + 'forgetPassword', {email, name}).subscribe(res => callback(res))
  }
}
