import { Injectable } from '@angular/core';
import {base} from './config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeService {
  url = base + 'nodes/change';
  constructor(public http: HttpClient, public auth: AuthService) { }

  sendChanges(data, callback) {
    this.http.post(this.url, data, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe(res => {
      callback(res);
    })
  }

}
