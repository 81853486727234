import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MailService} from '../../services/mail.service';
import {Node} from '../../models/node';
import {NodeService} from '../../services/node.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user = {email: '', password: ''};
  node = new Node();
  lat = 0;
  lng = 0;
  nodes = [];

  constructor(public auth: AuthService, public router: Router, public sMail: MailService) { }

  ngOnInit() {
  }

  login() {
    this.auth.login(this.user.email, this.user.password, res => {
      if (res.status) {
        this.auth.saveLogInfo(res.data);
        this.router.navigateByUrl('main/home');
      }
      else alert('Failed to login. Check the credential!');
    })
  }

  sendContactUsMail(email, title, message, modal) {
    console.log(email.value, title.value, message.value);
    this.sMail.sendMail(email.value, title.value, message.value, res => {
      if (res.status) alert('الرسالة ارسلت بنجاح');
      modal.style.display = 'none';
    });
    email.value = '';
    title.value = '';
    message.value ='';
  }

  sendForgetPasswordMail(email, name, modal) {
    this.sMail.sendForgetPasswordMail(email.value, name.value, res => {
      if (res.status) alert('الرسالة ارسلت بنجاح');
      modal.style.display = 'none';
    });
    email.value = '';
    name.value = '';
  }

  getCurrentLocation() {
    this.lat = 0;
    this.lng = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }


  createAccount(modal) {
    this.auth.createAccount(this.node, res => {
      if (res.status) {
        alert('تم انشاء الحساب');
        modal.style.display = 'none';
      } else {
        alert(res.data)
      }
    });
  }
}
