import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {base} from './config';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  url = base + 'posts';
  constructor(public http: HttpClient, public auth: AuthService) { }

  savePost(formData, callback) {
    this.http.post(this.url, formData, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  getPosts(callback) {
    this.http.get(this.url, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  saveComment(param, callback) {
    this.http.post(this.url + '/comment', param, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }


  getComments(postId, callback) {
    this.http.get(this.url + '/comments?post=' + postId, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  getLikes(postId, callback) {
    this.http.get(this.url + '/likes?post=' + postId, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  likeAPost(postId, callback) {
    this.http.post(this.url + '/likes', {postId: postId}, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  deleteComment(id, callback) {
    this.http.delete(this.url + '/comments/' + id, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

  deletePost(id, callback) {
    this.http.delete(this.url + '/' + id, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }
}
