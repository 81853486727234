import { Component, OnInit } from '@angular/core';
import {NodeService} from '../../../services/node.service';
import {Node} from '../../../models/node';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {base} from '../../../services/config';
let moment = require('moment-hijri');
declare let go: any;
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedNode = new Node();
  secondSelectedNode = new Node();
  lat = 0;
  lng = 0;
  nodes = [];
  nodesWithoutInVisible = [];
  baseUrl = base;
  myDiagram: any;
  temp: any;

  constructor(public nodeService: NodeService, public router: Router, public authService: AuthService) { }

  ngOnInit() {
    this.nodeService.getRoot(res => {
      res.forEach(d => {
        let node = d;
        node.key = node.id;
        node.parent = node.pid;
        node.source = this.baseUrl + 'uploads/' + node.img;
        node.img = this.baseUrl + 'uploads/' + node.img;
        this.nodes.push(node);
        if (d.isActive == 1) this.nodesWithoutInVisible.push(node);
      });
      this.authService.getRole() == 'admin'?
        this.initForAdmin(this.nodesWithoutInVisible):
        this.initForUser(this.nodesWithoutInVisible);
    })
  }

  createNode(addModal) {
    if(this.selectedNode.name.trim() == "" || this.selectedNode.nickName.trim() == "") {
      alert("رجاء املأ الحقول كلها!!");
      return;
    }
    console.log('create');
    addModal.style.display = 'none';
    this.nodeService.addNode(this.selectedNode, res => {
      if (res.status) {
        this.selectedNode.key = res.data.id;
        this.selectedNode.id = res.data.id;
        this.selectedNode.parent = this.selectedNode.pid;
        this.selectedNode.source = this.baseUrl + 'uploads/' + res.data.img;
        this.selectedNode.img = this.baseUrl + 'uploads/' + res.data.img;

        this.nodes.push(this.selectedNode);
        this.myDiagram.startTransaction("make new node");
        this.myDiagram.model.addNodeData(this.selectedNode);
        this.myDiagram.commitTransaction("make new node");
      } else {
        alert('لم تكتمل عملية الاضافة!!')
      }
    })
  }

  getCurrentLocation() {
    this.lat = 0;
    this.lng = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  showChart(infoModal, chartModal) {
    infoModal.style.display = 'none';
    chartModal.style.display = 'block';
    this.secondSelectedNode.parents = [];
    this.secondSelectedNode.brothers = [];
    this.secondSelectedNode.children = [];
    this.secondSelectedNode.partners = [];
    this.nodes.forEach(n => {
      if (n.id == this.secondSelectedNode.pid || this.secondSelectedNode.pid == n.hid) this.secondSelectedNode.parents.push(n);
      if (n.pid == this.secondSelectedNode.pid && n.id != this.secondSelectedNode.id)this.secondSelectedNode.brothers.push(n);
      if (n.pid == this.secondSelectedNode.id) this.secondSelectedNode.children.push(n);
      if(this.secondSelectedNode.gender == 'male') {
        if (n.hid == this.secondSelectedNode.id) this.secondSelectedNode.partners.push(n)
      } else {
        if (n.id == this.secondSelectedNode.hid) this.secondSelectedNode.partners.push(n)
      }
    });
  }

  isNodeSelected() {
    return this.selectedNode.id != -1;
  }

  getParent(id) {
    const node = this.nodes.filter(s => {
      return s.id == id
    });
    if (node.length > 0) return node[0].name;
    return null;
  }

  updateNode(addModal) {
    console.log('update');
    if(this.selectedNode.name.trim() == "" || this.selectedNode.nickName.trim() == "") {
      alert("رجاء املأ الحقول كلها!!");
      return;
    }
    addModal.style.display = 'none';
    this.nodeService.updateNode(this.selectedNode.id, this.selectedNode, res => {
      if (res.status) {
        this.myDiagram.startTransaction("edit the user");
        if (this.selectedNode.isActive == 0) {
          const node = this.myDiagram.findNodeForKey(this.temp.key);
          this.myDiagram.remove(node);
        } else {
          this.myDiagram.model.setDataProperty(this.temp, "name", this.selectedNode.name);
          this.myDiagram.model.setDataProperty(this.temp, "nickName", this.selectedNode.nickName);
          this.myDiagram.model.setDataProperty(this.temp, "nameEn", this.selectedNode.nameEn);
          this.myDiagram.model.setDataProperty(this.temp, "email", this.selectedNode.email);
          this.myDiagram.model.setDataProperty(this.temp, "phone", this.selectedNode.phone);
          this.myDiagram.model.setDataProperty(this.temp, "sequence", this.selectedNode.sequence);
          this.myDiagram.model.setDataProperty(this.temp, "about", this.selectedNode.about);
          this.myDiagram.model.setDataProperty(this.temp, "location", this.selectedNode.location);
          this.myDiagram.model.setDataProperty(this.temp, "birthDate", this.selectedNode.birthPlace);
          this.myDiagram.model.setDataProperty(this.temp, "birthPlace", this.selectedNode.birthPlace);
          this.myDiagram.model.setDataProperty(this.temp, "gender", this.selectedNode.gender);
          this.myDiagram.model.setDataProperty(this.temp, "parent", this.selectedNode.pid);
          this.myDiagram.model.setDataProperty(this.temp, "wife", this.selectedNode.hid);
        }
        this.myDiagram.commitTransaction("end edit the user");
      }
    })
  }

  selectEvent(item) {
    this.selectedNode.hid = item.id;
  }

  getPartner(node) {
    let pNode = null;
    if (node.gender == 'female') {
      pNode = this.nodes.filter(s => {
        return s.id == node.hid
      });
    } else {
      pNode = this.nodes.filter(s => {
        return s.hid == node.id
      });
    }
    if (pNode.length > 0) return pNode[0].name;
    return 'لا توجد معلومات';
  }

  NavigateToChat(id: number) {
    this.router.navigate(['main/chats'], { state: { id: id } });
  }

  getPartners(nodes: any[], gender: string) {
    return nodes.filter(f => f.gender != gender);
  }

  changeProfilePic(files) {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('id', this.selectedNode.id.toString());
    this.nodeService.changePic(formData, res => {
      if (res.status) {
        alert("Picture changed");
        let node = this.nodes.filter(f => f.id == this.selectedNode.id)[0];
        node.img = this.baseUrl + 'uploads/' + res.data.img;
        node.source = this.baseUrl + 'uploads/' + res.data.img;
        this.selectedNode.img = this.baseUrl + 'uploads/' + res.data.img;
        this.selectedNode.source = this.baseUrl + 'uploads/' + res.data.img;
        this.myDiagram.startTransaction("edit the user");
        this.myDiagram.model.setDataProperty(this.temp, "source", this.selectedNode.source);
        this.myDiagram.commitTransaction("end edit the user");
        //this.selectedNode.img = res.data.img;
      }
      else alert("There is error while changing the profile picture");
    });
  }



  initForAdmin(nodes) {
    let $ = go.GraphObject.make;  // for conciseness in defining templates
    this.myDiagram =
      $(go.Diagram, "myDiagramDiv",  // must be the ID or reference to div
        {
          "toolManager.hoverDelay": 100,  // 100 milliseconds instead of the default 850
          allowCopy: false,
          layout:  // create a TreeLayout for the family tree
            $(go.TreeLayout,
              { angle: 90, nodeSpacing: 10, layerSpacing: 40, layerStyle: go.TreeLayout.LayerUniform })
        });
    let bluegrad = '#90CAF9';
    let pinkgrad = '#F48FB1';

    // replace the default Node template in the nodeTemplateMap
    this.myDiagram.nodeTemplate =
      $(go.Node, "Auto",
        { deletable: false, toolTip: tooltiptemplate },
        new go.Binding("text", "name"),

        $(go.Shape, "Rectangle",
          {
            fill: "lightgray",
            stroke: null, strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            alignment: go.Spot.Center
          },
          {
            contextMenu:     // define a context menu for each node
              $("ContextMenu", { width: 150 },  // that has one button
                $("ContextMenuButton",
                  $(go.TextBlock, "اضافة", { font: "12px 'Cairo'", margin: 6,}),
                  {click: (e, obj) => {
                      const data = obj.part.data;
                      this.selectedNode = new Node();
                      this.selectedNode.pid = data.id;
                      this.selectedNode.id = -1;
                      document.getElementById("modalAddChild").style.display = "block";
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "حذف", { font: "12px", margin: 6,}),
                  { click: (e, obj) => {
                      const data = obj.part.data;
                      const node = this.myDiagram.findNodeForKey(data.key);
                      this.nodeService.onRemove(data.id, res => {
                        if (res.status) {
                          this.myDiagram.startTransaction();
                          this.myDiagram.remove(node);
                          this.myDiagram.commitTransaction("deleted node");
                        }
                      });
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "تعديل", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.temp = obj.part.adornedPart.data;
                      this.selectedNode = {...obj.part.data};
                      document.getElementById('modalAddChild').style.display = 'block';
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "معلومات", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.temp = obj.part.adornedPart.data;
                      this.selectedNode = {...obj.part.data};
                      document.getElementById('infoModal').style.display = 'block';
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "خريطة", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.secondSelectedNode = obj.part.data;
                      const infoModal = document.getElementById('infoModal');
                      const chartModal = document.getElementById('modalShowChart');
                      this.showChart(infoModal, chartModal);
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "دردشة", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.selectedNode = obj.part.data;
                      this.NavigateToChat(this.selectedNode.id)
                    }
                  })
              )  // end Adornment
          },
          new go.Binding("fill", "gender", genderBrushConverter)),
        $(go.Panel, 'Horizontal',
          $(go.Picture,
            {
              name: "Picture",
              desiredSize: new go.Size(50, 60),
              margin: new go.Margin(6, 8, 6, 10),
            },
            new go.Binding("source")),
          $(go.Panel, "Table", {
              maxSize: new go.Size(150, 999),
              margin: new go.Margin(6, 10, 0, 3),
              defaultAlignment: go.Spot.Left
            },
            $(go.TextBlock,
              {
                row: 0, column: 0, columnSpan: 5,
                font: "12pt Segoe UI,sans-serif",
                isMultiline: false,
                minSize: new go.Size(10, 16)
              },
              new go.Binding("text", "name")),
            $(go.TextBlock,
              {
                row: 1, column: 1, columnSpan: 4,
                isMultiline: false,
                minSize: new go.Size(10, 14),
                margin: new go.Margin(0, 0, 0, 3)
              },
              new go.Binding("text", "nickName")),
          )
        ),

      );
    // define the Link template
    this.myDiagram.linkTemplate =
      $(go.Link,  // the whole link panel
        { routing: go.Link.Orthogonal, corner: 5, selectable: true },
        $(go.Shape, { strokeWidth: 2, stroke: '#424242' }));  // the gray link shape
    // here's the family data
    let nodeDataArray = nodes;

    //this.myDiagram.addDiagramListener("ObjectSingleClicked", (e) => this.openInfoModal(e));
    this.myDiagram.model = new go.TreeModel(nodeDataArray);



    // get tooltip text from the object's data
    function tooltipTextConverter(person) {
      return 'Born in: ' + person.birthDate;
    }
    // define tooltips for nodes
    var tooltiptemplate =
      $("ToolTip",
        { "Border.fill": "whitesmoke", "Border.stroke": "black" },
        $(go.TextBlock,
          {
            font: "bold 8pt Helvetica, bold Arial, sans-serif",
            wrap: go.TextBlock.WrapFit,
            margin: 5
          },
          new go.Binding("text", "", tooltipTextConverter))
      );
    // define Converters to be used for Bindings
    function genderBrushConverter(gender) {
      if (gender.toLowerCase() === "male") return bluegrad;
      else return pinkgrad;
    }
  //this.myDiagram.initialScale = 0.1;
    this.myDiagram.initialAutoScale = go.Diagram.Uniform;
  }
  initForUser(nodes) {
    let $ = go.GraphObject.make;  // for conciseness in defining templates
    this.myDiagram =
      $(go.Diagram, "myDiagramDiv",  // must be the ID or reference to div
        {
          "toolManager.hoverDelay": 100,  // 100 milliseconds instead of the default 850
          allowCopy: false,
          layout:  // create a TreeLayout for the family tree
            $(go.TreeLayout,
              { angle: 90, nodeSpacing: 10, layerSpacing: 40, layerStyle: go.TreeLayout.LayerUniform })
        });
    let bluegrad = '#90CAF9';
    let pinkgrad = '#F48FB1';

    // replace the default Node template in the nodeTemplateMap
    this.myDiagram.nodeTemplate =
      $(go.Node, "Auto",
        { deletable: false, toolTip: tooltiptemplate },
        new go.Binding("text", "name"),

        $(go.Shape, "Rectangle",
          {
            fill: "lightgray",
            stroke: null, strokeWidth: 0,
            stretch: go.GraphObject.Fill,
            alignment: go.Spot.Center
          },
          {
            contextMenu:     // define a context menu for each node
              $("ContextMenu", { width: 150 },  // that has one button
                $("ContextMenuButton",
                  $(go.TextBlock, "معلومات", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.temp = obj.part.adornedPart.data;
                      this.selectedNode = {...obj.part.data};
                      document.getElementById('infoModal').style.display = 'block';
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "خريطة", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.secondSelectedNode = obj.part.data;
                      const infoModal = document.getElementById('infoModal');
                      const chartModal = document.getElementById('modalShowChart');
                      this.showChart(infoModal, chartModal);
                    }
                  }),
                $("ContextMenuButton",
                  $(go.TextBlock, "دردشة", { font: "16px", margin: 6,}),
                  { click: (e, obj) => {
                      this.selectedNode = obj.part.data;
                      this.NavigateToChat(this.selectedNode.id)
                    }
                  })
              )  // end Adornment
          },
          new go.Binding("fill", "gender", genderBrushConverter)),
        $(go.Panel, 'Horizontal',
          $(go.Picture,
            {
              name: "Picture",
              desiredSize: new go.Size(50, 60),
              margin: new go.Margin(6, 8, 6, 10),
            },
            new go.Binding("source")),
          $(go.Panel, "Table", {
              maxSize: new go.Size(150, 999),
              margin: new go.Margin(6, 10, 0, 3),
              defaultAlignment: go.Spot.Left
            },
            $(go.TextBlock,
              {
                row: 0, column: 0, columnSpan: 5,
                font: "12pt Segoe UI,sans-serif",
                isMultiline: false,
                minSize: new go.Size(10, 16)
              },
              new go.Binding("text", "name")),
            $(go.TextBlock,
              {
                row: 1, column: 1, columnSpan: 4,
                isMultiline: false,
                minSize: new go.Size(10, 14),
                margin: new go.Margin(0, 0, 0, 3)
              },
              new go.Binding("text", "nickName")),
          )
        ),

      );
    // define the Link template
    this.myDiagram.linkTemplate =
      $(go.Link,  // the whole link panel
        { routing: go.Link.Orthogonal, corner: 5, selectable: true },
        $(go.Shape, { strokeWidth: 2, stroke: '#424242' }));  // the gray link shape
    // here's the family data
    let nodeDataArray = nodes;

    //this.myDiagram.addDiagramListener("ObjectSingleClicked", (e) => this.openInfoModal(e));
    this.myDiagram.model = new go.TreeModel(nodeDataArray);



    // get tooltip text from the object's data
    function tooltipTextConverter(person) {
      return 'Born in: ' + person.birthDate;
    }
    // define tooltips for nodes
    var tooltiptemplate =
      $("ToolTip",
        { "Border.fill": "whitesmoke", "Border.stroke": "black" },
        $(go.TextBlock,
          {
            font: "bold 8pt Helvetica, bold Arial, sans-serif",
            wrap: go.TextBlock.WrapFit,
            margin: 5
          },
          new go.Binding("text", "", tooltipTextConverter))
      );
    // define Converters to be used for Bindings
    function genderBrushConverter(gender) {
      if (gender.toLowerCase() === "male") return bluegrad;
      else return pinkgrad;
    }
  //this.myDiagram.initialScale = 0.1;
    this.myDiagram.initialAutoScale = go.Diagram.Uniform;
  }
  getSearch(value) {
    if (value <= 0) return;
    else {
      if (value) {
      this.myDiagram.startTransaction("highlight search");
        // search four different data properties for the string, any of which may match for success
        // create a case insensitive RegExp from what the user typed
        var regex = new RegExp(value, "i");
        var results = this.myDiagram.findNodesByExample({ name: regex },
          { nickName: regex },
          { key: regex }
          );
        this.myDiagram.highlightCollection(results);
        // try to center the diagram at the first node that was found
        if (results.count > 0) {
          this.myDiagram.scale = 2;
          this.myDiagram.centerRect(results.first().actualBounds);
        }
      } else {  // empty string only clears highlighteds collection
        this.myDiagram.clearHighlighteds();
      }
      this.myDiagram.commitTransaction("highlight search");
    }
  }

  getSelectedParent() {
    const parent = this.nodes.filter(f => f.id == this.selectedNode.pid);
    return parent.length > 0? parent[0].name: '';
  }

  getSelectedWife() {
    const wife = this.nodes.filter(f => f.id == this.selectedNode.hid);
    return wife.length > 0? wife[0].name: '';
  }
}
