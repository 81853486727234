import { Injectable } from '@angular/core';
import {base} from './config';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = base + 'auth/';

  constructor(public http: HttpClient) { }

  login(email, password, callback) {
    this.http.post(this.url + 'login', {email, password}).subscribe(res => callback(res))
  }

  checkToken(callback) {
    this.http.get(this.url + 'check', {
      headers: new HttpHeaders({
        Authorization: this.getToken()
      })}).subscribe(res => callback(res))
  }

  saveToken(token) {
    localStorage.token = token;
  }

  saveLogInfo(user) {
    localStorage.logInfo = JSON.stringify(user);
  }

  getToken() {
    if (localStorage.logInfo) return JSON.parse(localStorage.logInfo).token;
    return null;
  }

  getId() {
    if (localStorage.logInfo) return JSON.parse(localStorage.logInfo).id;
    return null;
  }

  getName() {
    if (localStorage.logInfo) return JSON.parse(localStorage.logInfo).name;
    return null;
  }

  getRole() {
    if (localStorage.logInfo) return JSON.parse(localStorage.logInfo).role;
    return null;
  }

  logout() {
    localStorage.clear()
  }

  changePassword(oldPassword, newPassword, callback) {
    this.http.post(this.url + 'changePassword', {oldPassword, newPassword}, {
      headers: new HttpHeaders({
        Authorization: this.getToken()
      })
    }).subscribe(res => callback(res))
  }

  createAccount(data, callback) {
    this.http.post(this.url + 'createAccount', data).subscribe(res => callback(res))
  }

}
