import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {TreeModule} from 'angular-tree-component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { NavbarComponent } from './components/navbar/navbar.component';
import {AgmCoreModule} from '@agm/core';
import { HomeComponent } from './components/main/home/home.component';
import {RouterModule} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AlbumComponent } from './components/main/album/album.component';
import { ChatsComponent } from './components/main/chats/chats.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {base} from './services/config';
import { SetupAccountComponent } from './components/main/setup-account/setup-account.component';
import { LandingComponent } from './components/landing/landing.component';
const config: SocketIoConfig = { url: base, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    LoginComponent,
    MainComponent,
    AlbumComponent,
    ChatsComponent,
    SetupAccountComponent,
    LandingComponent,
  ],
  imports: [
    BrowserModule,
    TreeModule.forRoot(),
    FormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCCWsyir25QAuD5DpGoClWoBL22P835PpI'
    }),
    RouterModule.forRoot([
      {path: '', component: LandingComponent},
      {path: 'main', component: MainComponent, children: [
          {path: 'home', component: HomeComponent},
          {path: 'album', component: AlbumComponent},
          {path: 'chats', component: ChatsComponent},
          {path: 'setup', component: SetupAccountComponent},
        ]},
      {path: 'login', component: LoginComponent},
      {path: '**', redirectTo: ''}
    ], {useHash: true}),
    SocketIoModule.forRoot(config)
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
