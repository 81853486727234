import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from 'protractor';
import {base} from './config';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
  url = base + 'nodes';

  constructor(public http: HttpClient, public auth: AuthService) { }

  getRoot(callback) {
    this.http.get(this.url, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe((res: Config) => {
      if (res.status) callback(res.data);
      else {
        console.log(res);
        callback([])
      }
    });
  }

  getChildren(id, callback) {
    this.http.get(this.url + 'children?id=' + id, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe((res: Config) => {
      callback(res.data)
    });
  }

  onRemove(id, callback) {
    this.http.delete(this.url + '?id=' + id, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe((res: Config) => {
      callback(res);
    });
  }

  addNode(node, callback) {
    this.http.post(this.url, node, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe(res => {
      callback(res);
    })
  }
  updateNode(id, node, callback) {
    this.http.put(this.url + "?id=" + id, node, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe(res => {
      callback(res);
    })
  }

  changePic(formData, callback) {
    this.http.post(this.url + '/changePic', formData, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }

}
