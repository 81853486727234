import { Component, OnInit } from '@angular/core';
import {Node} from '../../../models/node';
import {NodeService} from '../../../services/node.service';
import {AuthService} from '../../../services/auth.service';
import {ChangeService} from '../../../services/change.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent implements OnInit {
  lat = 0;
  lng = 0;
  node = new Node();
  nodes = [];

  constructor(public sNode: NodeService, public sAuth: AuthService,
              public sChange: ChangeService, public router: Router) { }

  ngOnInit() {
    this.sNode.getRoot(res => {
      this.nodes = res;
      res.forEach(r => {
        if (r.id == this.sAuth.getId()) this.node = r;
      })
    })
  }

  getCurrentLocation() {
    this.lat = 0;
    this.lng = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getPartners(nodes, gender) {
    return nodes.filter(f => f.gender != gender);
  }

  selectEvent(item) {
    this.node.hid = item.id;
  }

  sendChanges() {
    this.sChange.sendChanges(this.node, res => {
      if (res.status) this.router.navigateByUrl('main/home');
      else alert('لم يتم ارسال التغيررات');
    })
  }
}
