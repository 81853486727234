import { Component, OnInit } from '@angular/core';
import {NodeService} from '../../../services/node.service';
import { Socket } from 'ngx-socket-io';
import {AuthService} from '../../../services/auth.service';
import {MessageService} from '../../../services/message.service';
import {Router} from '@angular/router';
import {base} from '../../../services/config';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {
  users = [];
  tempUsers = [];
  selectedUser:any;
  selectedUserId = -1;
  id = 0;
  baseUrl = base;

  private selectedUserMessage = [];

  constructor(public node: NodeService,
              private socket: Socket,
              public auth: AuthService,
              public message: MessageService,
              private router: Router) {
    let state = this.router.getCurrentNavigation().extras.state;
    if (state != null) {
      this.selectedUserId = state.id;
    }
  }

  ngOnInit() {
    this.id = this.auth.getId();
    this.node.getRoot(res => {
      this.users = res;
      this.tempUsers = this.users;
      let match = this.users.filter(f => f.id == this.selectedUserId);
      if (match.length > 0) {
        this.selectedUser = match[0];
        this.getUserMessages();
      }
    });
    this.getNotSeenNo();
    this.getMessageSocket();
  }

  searchUsers(value: string) {
    if (value.trim() == '') this.users = this.tempUsers;
    else this.users = this.tempUsers.filter(f => f.name.toLowerCase().includes(value.toLowerCase()));
  }

  sendMessage(msg){
    this.socket.emit("message", {token: this.auth.getToken() , message: msg.value, to:this.selectedUser.id});
    msg.value = '';
  }

  getMessageSocket() {
    return this.socket
      .fromEvent("getMessage").subscribe(data => {
        this.selectedUserMessage.reverse().push(data);
        this.selectedUserMessage.reverse();
        this.getNotSeenNo();
      })
  }

  getUserMessages() {
    this.selectedUser.notSeen = 0;
    this.message.getMessages(this.selectedUser.id, res => {
      this.selectedUserMessage = res.data.reverse();
    })
  }


  getNotSeenNo() {
    this.message.getNotSeenNo(res => {
      if (res.status) {
        const data = res.data;
        this.users.forEach(u => {
          data.forEach(d => {
            u.notSeen = 0;
            if (u.id == d.fromUser && d.toUser == this.id) u.notSeen = d.number;
          })
        });
      }
    });
  }

}
