import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {MessageService} from '../../services/message.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() nav = new EventEmitter();
  page = '/main/home';
  name = '';
  noOfNewMessages = 0;

  constructor(public router: Router, public auth: AuthService, public message: MessageService) { }

  ngOnInit() {
    this.page = this.router.url;
    this.name = this.auth.getName();
    this.message.getNotSeenNo(res => {
      if (res.status)
        res.data.forEach(d => { this.noOfNewMessages += d.number})
    })
  }

  showMenu() {
    let m = document.getElementById('navbarTogglerDemo01');
    if (m.style.display == 'none' || m.style.display == '') m.style.display = 'block';
    else m.style.display = 'none';
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('login');
  }

  changePassword(old, newPass, modal) {
    this.auth.changePassword(old.value, newPass.value, res => {
      if (res.status) {
        alert('تم تغيير الرمز السري');
        old.value = '';
        newPass.value = '';
        modal.style.display = 'none';
      } else alert('لم يتم التغيير')
    });
  }
}
