import { Component, OnInit } from '@angular/core';
import {AlbumService} from '../../../services/album.service';
import {Post} from '../../../models/post';
import {base} from '../../../services/config';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  newPost = new Post();
  posts = [];
  url = base;
  selectedPost = new Post();
  comments = [];
  postLikes = 0;

  constructor(public album: AlbumService, public auth: AuthService) { }

  ngOnInit() {
    this.getPosts();
  }

  getPosts() {
    this.album.getPosts(res => {
      if (res.status) this.posts = res.data;
    });
  }

  uploadFile(file) {
    this.newPost.pic = file[0];
  }

  savePost(addPost) {
    const formData = new FormData();
    formData.append('file', this.newPost.pic);
    formData.append('title', this.newPost.title);
    formData.append("description", this.newPost.description);
    this.album.savePost(formData, res => {
      if (res.status) this.getPosts();
    });
    this.closeAddPostModal(addPost);
  }

  closeAddPostModal(addPost) {
    addPost.style.display = 'none';
    this.newPost = new Post();
  }

  getDateFormat(date) {
    return date.substr(0, date.indexOf('T'))
  }

  saveComment(textarea) {
    const postComment = {postId: this.selectedPost.id, content: textarea.value.trim()};
    this.album.saveComment(postComment, res => {
      if (res.status) {
        this.getComments(this.selectedPost.id);
        textarea.value = "";
      }
    })
  }

  PreviewPost(p, previewPost) {
    this.selectedPost = p;
    previewPost.style.display = 'block';
    this.getComments(p.id);
    this.getLikes(p.id);

  }

  getComments(postId) {
    this.album.getComments(postId,res => {
      if (res.status) {
        this.comments = res.data.reverse();
      }
    });
  }

  likeThisPost(id) {
    this.album.likeAPost(id, res => {
      if (!res.status) alert(res.data);
      else this.getLikes(id);
    });
  }

  getLikes(postId) {
    this.album.getLikes(postId, res => {
      if (res.status) this.postLikes = res.data;
    });
  }

  removeComment(id) {
    this.album.deleteComment(id, res => {
      if (res.status) {
        alert("Comment deleted successfully");
        this.getComments(this.selectedPost.id);
      }
    });
  }

  deletePost(id, modal) {
    this.album.deletePost(id, res => {
      if (res.status) {
        alert("Post deleted successfully");
        this.getPosts();
        modal.style.display = 'none';
      }
    });
  }
}
