import { Injectable } from '@angular/core';
import {base} from './config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  url = base + 'messages';
  constructor(public http: HttpClient, public auth: AuthService) { }

  getMessages(forUser, callback) {
    this.http.get(this.url + '?id=' + forUser, {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })
    }).subscribe(res => callback(res))
  }

  getNotSeenNo(callback) {
    this.http.get(this.url + '/notSeen', {
      headers: new HttpHeaders({
        Authorization: this.auth.getToken()
      })}).subscribe(res => callback(res))
  }
}
